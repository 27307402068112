import React, { useState } from 'react'
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap'

const FindErrorForm = () => {
  const [validated, setValidated] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [zipValue, setZipValue] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const processForm = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(true)
    setFormDisabled(true)

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        g: 'Vxbr3Y',
        $fields: 'zip',
        email: emailValue,
        zip: zipValue,
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={processForm}
      data-ad-element-type="Request Form"
      data-ad-text={
        "Darn, we haven't quite..."
      }
      data-ad-button-location="body"
      id="zipRequestForm"
    >
      <Form.Group className="mb-1">
        <Form.Control
          type="email"
          placeholder="Email"
          className="border-0 p-1"
          required
          value={emailValue}
          pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
          onChange={(e) => setEmailValue(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col md="12">
          <Form.Group className="mb-1 rounded-5">
            <Form.Control required type="text" placeholder="Zip" className="border-0 p-1" onChange={(e) => setZipValue(e.target.value)} />
            <Form.Control.Feedback type="invalid">
              Please provide your zip
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Button
        variant="dark"
        className="btn-lg w-100"
        type="submit"
        disabled={formDisabled}
        data-ad-element-type="request form submit"
        data-ad-text="Request"
        data-ad-button-location="body"
        data-ad-button-id="version 1"
      >
        Request
      </Button>
      <Alert variant="success" className="mt-1" show={showAlert}>
        Thanks! We'll be in touch.
      </Alert>

    </Form>

  )
}

export default FindErrorForm
