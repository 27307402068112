import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

const MapDistanceDropdown = (props) => {
  const { mapDistance, setMapDistance, isLoading } = props

  const handleSelect = (e) => {
    setMapDistance(e)
  }

  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-distance"
      title={`${mapDistance} miles`}
      variant="outline-secondary"
      className="p-0"
      align="start"
      onSelect={handleSelect}
      disabled={isLoading}
    >
      <Dropdown.Item eventKey="10" value={10}>10 miles</Dropdown.Item>
      <Dropdown.Item eventKey="15" value={10}>15 miles</Dropdown.Item>
      <Dropdown.Item eventKey="20">20 miles</Dropdown.Item>
      <Dropdown.Item eventKey="25">25 miles</Dropdown.Item>
    </DropdownButton>
  )
}

export default MapDistanceDropdown
