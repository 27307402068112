import * as React from 'react'
import FindErrorForm from './find-error-form'

export const ErrorMapOverlay = () => {
  return (
    <div className="position-absolute top-0 start-0 w-100 h-100 p-1 text-center lh-1 fs-2" style={{ zIndex: 100, backgroundColor: 'rgba(242, 69, 0, 0.9)' }}>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <p className="text-light">Darn, we haven't quite made it to your area yet.</p>
        <p className="text-light mb-2">Send us your zip code so we know where to head next!</p>
        <FindErrorForm />
      </div>
    </div>
  )
}
