import axios from 'axios'
import { useState } from 'react'
// import { useLocationContext } from "../utils/locationContext";

export default function useFetch() {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const muffinKey = '407bc7e2-a620-4205-bd71-77ae0d00d128'

  const fetchData = async (mapCenter, distance = 5, products = [], channels = []) => {
    if (mapCenter) {
      const queryURL = `https://api.muffindata.com/v1/locator?lat=${mapCenter.lat}&lng=${
        mapCenter.lng
      }&page_size=100&dist=${distance}${products.length > 0 ? `&product_name=${products.join(',')}` : ''}${
        channels.length > 0 ? `&primary_channel=${channels.join(',')}` : ''
      }`

      try {
        const postURL = queryURL
        const postHeaders = {
          headers: {
            'api-key': muffinKey,
          },
        }
        setLoading(true)
        console.log(postURL)
        const response = await axios.get(postURL, postHeaders)
        setData(response.data.results)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return { data, error, loading, fetchData }
}
