import { faLocationArrow, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const FieldGoogleAutocomplete = (props) => {
  const { isLoaded, searchValue, setSearchValue } = props
  const [placeholderValue, setPlaceholderValue] = useState('Enter a location')
  const [onInitialRender, setOnInitialRender] = useState(false)
  const [clearButtonEnabled, setClearButtonEnabled] = useState(true)
  const fieldRef = useRef(null)

  useEffect(() => {
    if (searchValue && !onInitialRender) {
      setPlaceholderValue(searchValue)
      if (searchValue !== 'Enter your address') {
        setOnInitialRender(true)
      }
    }
    setClearButtonEnabled(true)
  }, [searchValue])

  const handleFocus = () => {
    fieldRef.current.clearValue()
    setSearchValue(null)
    setPlaceholderValue('')
  }

  const clearLocationField = () => {
    setSearchValue(null)
    setPlaceholderValue('')
    fieldRef.current.focus()
    setClearButtonEnabled(false)
  }

  return isLoaded && (
  <div className="form-control form-control-lg input__element position-relative" style={{ zIndex: 1000, paddingTop: 0, paddingBottom: 0 }}>
    <div className="position-absolute text-primary" style={{ top: '10px', left: '4px' }}>
      <FontAwesomeIcon className="location__arrow" icon={faLocationArrow} />
    </div>
    <GooglePlacesAutocomplete
      apiOptions={{ language: 'en', region: 'us' }}
      autocompletionRequest={{
        componentRestrictions: { country: 'us' },
      }}
      withSessionToken
      minLengthAutocomplete={3}
      selectProps={{
        ref: fieldRef,
        onFocus: () => handleFocus(),
        onChange: setSearchValue,
        openMenuOnClick: false,
        styles: {
          option: (provided) => ({
            ...provided,
            fontFamily: 'Founders, system-ui, -apple-system, "Segoe UI", Roboto',
            fontSize: '1rem',
          }),
          control: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
          }),
        },
        components: { DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null },
        placeholder: placeholderValue,
      }}
    />
    {clearButtonEnabled && (
    <div className="position-absolute text-dark" style={{ top: '10px', right: '-10px', cursor: 'pointer' }} onClick={clearLocationField}>
      <FontAwesomeIcon icon={faXmark} className="location__close" />
    </div>
    )}
  </div>
  )
}

export default FieldGoogleAutocomplete
